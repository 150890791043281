export enum ChainId {
  MAINNET = 204,
  TESTNET = 7701,
}

export const NETWORK_URLS: {
  [chainId in ChainId]: string
} = {
  [ChainId.MAINNET]: `https://opbnb-mainnet-rpc.bnbchain.org`,
  [ChainId.TESTNET]: `https://eth.plexnode.wtf`,
}
