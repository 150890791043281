import { Token } from '@uniswap/sdk-core'

import { WBNB, Bnb } from './native-token'

import { ChainId } from 'constants/chains'
import { MAINNET, TESTNET } from './periphery'

export { WBNB, Bnb }

export const BNB = Bnb.onChain(ChainId.MAINNET)

/**
 * BnbDEX Tokens
 */

export const USDC = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd', 6, 'USDC', 'USDC'),
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, '0xc51534568489f47949A828C8e3BF68463bdF3566', 6, 'USDC', 'USDC'),
}

/**
 * LeetSwap
 */

export const DIFFUSION = makeToken('Leet', 'LEET', 18, {
  [ChainId.MAINNET]: MAINNET.diffusion,
  [ChainId.TESTNET]: TESTNET.diffusion || '0x067eC87844fBD73eDa4a1059F30039584586e09d',
})

export const XDIFFUSION = makeToken('xLEET', 'XLEET', 18, {
  [ChainId.MAINNET]: MAINNET.diffusionbar,
  [ChainId.TESTNET]: TESTNET.diffusionbar,
})

function makeToken(name: string, symbol: string, decimals: number, addresses: Record<ChainId, string>) {
  return {
    [ChainId.MAINNET]: new Token(ChainId.MAINNET, addresses[ChainId.MAINNET], decimals, symbol, name),
    [ChainId.TESTNET]: new Token(ChainId.TESTNET, addresses[ChainId.TESTNET], decimals, `${symbol}`, `${name}`),
  }
}
