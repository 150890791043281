/**
 *
 * https://github.com/diffusion-fi/v2-periphery/blob/main/scripts/config/config.ts
 *
 * When changing this also update: cypress/integration/contracts.ts
 *
 */

const PERIPHERY_TESTNET = {
  factory: '0x0000000000000000000000000000000000000000',
  weth9: '0x0000000000000000000000000000000000000000',
  router: '0x0000000000000000000000000000000000000000',
  mockUSDC: '0x0000000000000000000000000000000000000000',
  mockBNB: '0x0000000000000000000000000000000000000000',
  mockATOM: '0x0000000000000000000000000000000000000000',
  mockOSMOSIS: '0x0000000000000000000000000000000000000000',
  multicall2: '0x0000000000000000000000000000000000000000',
  LHS: '0x0000000000000000000000000000000000000000',
  RHS: '0x0000000000000000000000000000000000000000',
  testerAddress: '0x0000000000000000000000000000000000000000',
  //0x851e5cE9fa409B731f980a5E00FA889b58D9037D
  // 0xA2c659531B15bFf2556Ea7E12D477D3C8761ACD9
  //0x95BF964f113a75a3974E8164105e6e5A8D743b87
  // 0x62154D72C202f04CA50a3Ba5630052D0348f337A
  rewardToken: '0x0000000000000000000000000000000000000000',
  secondaryRewardToken: '0x0000000000000000000000000000000000000000',
  miniChef: '0x0000000000000000000000000000000000000000',
  complexRewarderTime: '0x0000000000000000000000000000000000000000',
  diffusion: '',
}

const MAINNET_PERIPHERY = {
  factory: '0xa2899c776bAAF9925d432F83C950D5054A6CF59C',
  weth9: '0x4200000000000000000000000000000000000006',
  router: '0xb3a737C3940287ABbFD831086f0F24868D3e6FC4',
  multicall2: '0x12a2B750ef42ED27A1B9ce9E56cdB901e814f642',
  //
  minichef: '0x0000000000000000000000000000000000000000',
  diffusion: '0xf2f41fE10D53e15Ba34e410987Eb0F986eC7509F',
}

export const MAINNET = {
  ...MAINNET_PERIPHERY,
  diffusionbar: '0x0000000000000000000000000000000000000000',
  airdrop: '0x0000000000000000000000000000000000000000',
}

export const TESTNET = {
  ...PERIPHERY_TESTNET,
  airdrop: '0x0000000000000000000000000000000000000000',
  diffusionbar: '0x0000000000000000000000000000000000000000',
}
